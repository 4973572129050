
import { arrayOf, object } from 'vue-types';
import modulesMixin from '@/mixins/modules';

export default {
  mixins: [modulesMixin],
  inheritAttrs: false,
  props: {
    modules: arrayOf(object()),
  },
};
